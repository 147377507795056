import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  Typography,
  Box,
  Stack,
  BoxProps,
  Avatar,
  Grid,
  Tooltip,
  Collapse,
} from '@mui/material';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import {
  BundleFormType,
  BundleMetaType,
  InstructorType,
  BundleCourseInstructorType,
} from '../../@types/bundle';
import ReviewRateStar from '../../components/ReviewRateStar';
import { fTSeparator } from '../../utils/formatNumber';

const defaultDisplay = 4;

interface BundleCoverProps {
  bundleData: BundleFormType;
  bundleMeta: BundleMetaType;
}

interface InstructorProps {
  instructor: InstructorType;
}

interface InstructorCoursesProps {
  courses: BundleCourseInstructorType[];
}

export default function Cover({ bundleData, bundleMeta }: BundleCoverProps) {
  const mainInstructors = bundleMeta.instructors.slice(0, defaultDisplay);
  const subInstructors = bundleMeta.instructors.slice(defaultDisplay);
  const [moreOpen, setMoreOpen] = useState(false);

  return (
    <CoverWrapStyle coverImage={bundleData.images.cover}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '@media (max-width: 899px)': {
            textAlign: 'center',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginY: '10px',
          }}
        >
          <Iconify
            icon={'clarity:bundle-solid'}
            sx={{
              width: '35px',
              height: '35px',
              backgroundColor: 'text.primary',
              padding: '5px',
              borderRadius: '50%',
              color: 'background.default',
            }}
          />
          <Typography variant="subtitle1" color="text.primary">
            Bundle
          </Typography>
        </Box>
        <Typography variant="h3" color="text.primary">
          {bundleData.title}
        </Typography>
        <Typography variant="subtitle1" color="text.primary">
          {bundleData.shortDescription}
        </Typography>
        <Box
          sx={{ marginTop: '10px', textAlign: 'left', color: 'text.primary' }}
        >
          <Stack direction="row">
            {Array(5)
              .fill(0)
              .map((_, index: number) => (
                <ReviewRateStar
                  key={`review-icon-${index}`}
                  width="15px"
                  height="15px"
                  display={bundleMeta.review.average - index}
                />
              ))}
          </Stack>
          {bundleMeta.review.average ? (
            <>
              {bundleMeta.review.average} (
              {fTSeparator(bundleMeta.review.count)} รีวิว)
            </>
          ) : (
            <>ยังไม่มีรีวิว</>
          )}{' '}
          จากผู้เรียน {fTSeparator(bundleMeta.studentCount)} ท่าน
        </Box>
        <Grid container sx={{ marginTop: '10px' }}>
          {mainInstructors.map((instructor) => (
            <Instructor key={instructor.id} instructor={instructor} />
          ))}
        </Grid>
        {subInstructors.length > 0 && (
          <>
            <Collapse in={moreOpen} sx={{ width: '100%' }}>
              <Grid container>
                {subInstructors.map((instructor) => (
                  <Instructor key={instructor.id} instructor={instructor} />
                ))}
              </Grid>
            </Collapse>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
            >
              <Iconify
                icon={
                  moreOpen
                    ? 'dashicons:arrow-up-alt2'
                    : 'dashicons:arrow-down-alt2'
                }
                onClick={() => setMoreOpen(!moreOpen)}
                sx={{ width: 16, height: 16, cursor: 'pointer' }}
              />
              <Typography
                onClick={() => setMoreOpen(!moreOpen)}
                sx={{ cursor: 'pointer' }}
              >
                {moreOpen ? 'ดูน้อยลง' : 'ผู้สอนทั้งหมด'}
              </Typography>
            </Grid>
          </>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <ReactPlayer
          url={bundleData.previewVideo}
          controls={true}
          width="100%"
          playing={false}
        />
      </Box>
    </CoverWrapStyle>
  );
}

interface CoverWrapProps extends BoxProps {
  coverImage?: String;
}

const CoverWrapStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'coverImage',
})<CoverWrapProps>(({ coverImage, theme }) => ({
  backgroundImage: `linear-gradient(${alpha(
    theme.palette.background.default,
    0.97,
  )}, ${alpha(theme.palette.background.default, 0.95)}), url("${coverImage}")`,
  padding: '85px 40px 135px 40px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  '@media (max-width: 899px)': {
    flexDirection: 'column-reverse',
    padding: '40px',
  },
}));

function Instructor({ instructor }: InstructorProps) {
  return (
    <Grid item xs={12} md={6}>
      <Box
        component={Link}
        to={`/search?search=${instructor.publicName}`}
        sx={{
          all: 'unset',
          userSelect: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{ minWidth: '50px', display: 'flex', justifyContent: 'center' }}
        >
          <Avatar
            src={instructor.imageUrl}
            alt="instructor-image"
            sx={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
            }}
          />
        </Box>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '@media (min-width: 900px)': {
              maxWidth: '190px',
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
              ':hover': {
                color: 'primary.main',
              },
            }}
          >
            {`${instructor.publicName}`}
          </Typography>
          <InstructorCourses courses={instructor.bundleCourses} />
        </Box>
      </Box>
    </Grid>
  );
}

function InstructorCourses({ courses }: InstructorCoursesProps) {
  return (
    <Tooltip
      title={
        <>
          {courses.map((course, index) => (
            <Box key={`Instructor-Tooltip-${index}`}>
              <Typography variant="caption">• {course.title}</Typography>
            </Box>
          ))}
        </>
      }
    >
      {courses.length === 1 ? (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {courses[0].title}
        </Typography>
      ) : (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          ดำเนินการสอนทั้งหมด {courses.length} หลักสูตร
        </Typography>
      )}
    </Tooltip>
  );
}
