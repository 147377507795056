import React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import ReviewRateStar from './ReviewRateStar'

export default function Rating({rate}: {rate: number}){
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Stack direction="row" justifyContent="start" alignItems="start">
        {Array(5).fill(0).map((_, index: number) => (
          <ReviewRateStar
            key={`review-icon-${index}`}
            width="15px"
            height="15px"
            display={rate - index}
          />
        ))}
      </Stack>
      <Typography variant="body2" sx={{ color: 'primary.main', marginTop: '6px' }}>{rate}</Typography>
    </Box>
  )
}
