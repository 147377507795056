import React, { useMemo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';

import { styled } from '@mui/material/styles';

import Iconify from '../../components/Iconify'

import axios from '../../utils/axios';

import useAuth from '../../hooks/useAuth';

import {ProjectSubmissionDataType} from '../../@types/projectSubmission';

import { useTheme } from '@mui/material/styles';

import ReactPlayer from 'react-player'

import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
  RHFUploadMultiFile,
  RHFUploadSingleFile
} from '../../components/hook-form';

type ProjectFormType = {
  name: string;
  description: string;
  coverImage: string;
  worksImages: string[];
  worksLink: string;
  youtubeLink: string;
  publicTerm: boolean;
  nonCopyTerm: boolean;
  galleryTerm: boolean;
  anonymous: boolean;
}

export default function Form({projectId, remainAttempt, submissionData}: {projectId: string, remainAttempt: number, submissionData? : ProjectSubmissionDataType}) {
  const {user} = useAuth()

  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const urlFormat = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  const youtubeLinkFormat = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/

  const ProjectFormSchema = Yup.object().shape({
    name: Yup.string().required('กรุณาใส่ชื่อโปรเจกต์ของคุณ'),
    description: Yup.string().required('กรุณาใส่คำอธิบายโดยย่อของโปรเจกต์'),
    coverImage: Yup.mixed().test('required', 'กรุณาใส่รูปปกของโปรเจกต์/ รูปภาพของคุณขนาดเกิน 2MB', (value) => value !== ''),
    worksImages: Yup.array().min(1, 'กรุณาใส่รูปภาพ/ รูปภาพของคุณขนาดเกิน 2MB').max(5, 'รูปภาพใส่ได้ 5 ภาพเท่านั้น'),
    worksLink: Yup.string().matches(urlFormat, 'ลิงก์ของคุณไม่ถูกต้อง').required('กรุณาใส่ลิงก์ของคุณ'),
    youtubeLink: Yup.string().matches(youtubeLinkFormat, 'ลิงก์ของคุณไม่ถูกต้อง').required('กรุณาใส่ลิงก์ของคุณ'),
    publicTerm: Yup.boolean().oneOf([true],'กรุณายอมรับข้อตกลง'),
    nonCopyTerm: Yup.boolean().oneOf([true],'กรุณายอมรับข้อตกลง'),
    galleryTerm: Yup.boolean().oneOf([true],'กรุณายอมรับข้อตกลง'),
  });

  const defaultValues = useMemo(
    () => ({
      name: submissionData ? submissionData.name : '',
      description: submissionData ? submissionData.description : '',
      coverImage: '',
      worksImages: [],
      worksLink: submissionData ? submissionData.worksLink : '',
      youtubeLink: submissionData ? submissionData.youtubeLink : '',
      publicTerm: true,
      nonCopyTerm: true,
      galleryTerm: true,
      anonymous: false
    } as any),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm< any >({
    resolver: yupResolver(ProjectFormSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: ProjectFormType) => {
    if( !youtubeError ) {
      setLoading(true)
      try {
        const submitData: {[key: string]: any} = { projectId: projectId,...data }
        const form = new FormData()
        Object.keys(submitData).map((key) =>
          Array.isArray(submitData[key]) ?
            submitData[key].map((item: any) => form.append(key === 'worksImages' ? 'worksImages[]' : key, item))
            :
            form.append(key, submitData[key]
          )
        )
        await axios.post(`/projects`, form)

        setTimeout(() => {
          reset()
          window.location.reload()
        }, 1000)

      } catch (error: any) {
        setLoading(false)
        if(typeof error === 'string') {
          setErrorMessages([error])
        } else {
          const messages = error?.message
          if (Array.isArray(messages)) {
            setErrorMessages(messages)
          } else if (typeof messages === 'string') {
            setErrorMessages([messages])
          } else {
            setErrorMessages(['Something went wrong'])
          }
        }
      }
    }
  };

  const handleCoverImageDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'coverImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleWorkImagesDrop = useCallback(
    (acceptedFiles: any) => {
      const prevImages = getValues('worksImages')
      const uploadImages = acceptedFiles.map((file: Blob | MediaSource) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      setValue(
        'worksImages',
        [...prevImages, ...uploadImages].slice(0, 5)
      );
    },
    [setValue]
  );

  const handleRemoveAllWorkImages = () => {
    setValue('worksImages', []);
  };

  const handleRemoveWorkImage = (file: File | string) => {
    const filteredItems = values.worksImages?.filter((_file: any) => _file !== file);
    setValue('worksImages', filteredItems);
  };

  const theme = useTheme()

  const autoFillStyles = {
    style: {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
      WebkitTextFillColor: theme.palette.text.secondary,
  }}

  function validateYoutubeLink(url: string) {
    const youtubeLinkFormat = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
    const isError = youtubeLinkFormat.test(url)
    return isError
  }

  const [youtubeError, setYoutubeError] = useState(false)

  return (
    <>
      {
        remainAttempt > 0 &&
        <Box sx={{paddingTop: '30px'}}>
          <Box sx={{display: 'flex', align: 'top'}}>
            <TitleStyle variant="h4">แบบฟอร์มสำหรับจัดส่งโปรเจกต์</TitleStyle>
            <ErrorTextStyle variant="subtitle2" color="error">ผู้เรียนสามารถดำเนินการส่งได้อีก {remainAttempt} ครั้งเท่านั้น</ErrorTextStyle>
          </Box>
          <FormWrapStyle>
            <FormProvider methods={methods} onSubmit={handleSubmit((data, event) => {onSubmit(data)}, () => setErrorMessages(['*ข้อมูลจำเป็นบางส่วนไม่สมบูรณ์']))}>

              <Box sx={{display: 'flex', marginBottom: '15px'}}>
                <TitleStyle variant="subtitle1">ชื่อของผู้เรียน</TitleStyle>
              </Box>
              <DisabledTextFieldStyle name="ownerName" placeholder='ชื่อ (Auto Filled)' value={`${user?.publicName}`} disabled/>

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">ชื่อโปรเจกต์</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <TextFieldStyle name="name" placeholder='ชื่อโครงการของคุณ' color='secondary' inputProps={autoFillStyles}/>

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">คำอธิบายโดยย่อของโปรเจกต์</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <TextFieldStyle
                name="description"
                placeholder='คำอธิบายโดยย่อของโครงการ'
                color='secondary'
                sx={{'& .MuiOutlinedInput-root': {minHeight: '130px', alignItems: 'start'} }}
                multiline
              />

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">รูปภาพหน้าปกโปรเจกต์</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <RHFUploadSingleFile
                name="coverImage"
                accept={{ 
                  'image/png': ['.png'], 
                  'image/jpg': ['.jpg'], 
                  'image/jepg': ['.jpeg'],
                  'image/webp': ['.webp'], 
                }}
                maxSize={2097152}
                onDrop={handleCoverImageDrop}
              />

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">รูปภาพภายในผลงาน (1-5 รูป)</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <RHFUploadMultiFile
                name="worksImages"
                showPreview
                accept={{
                  'image/png': ['.png'], 
                  'image/jpg': ['.jpg'], 
                  'image/jepg': ['.jpeg'],
                  'image/webp': ['.webp']
                }}
                maxSize={2097152}
                onDrop={handleWorkImagesDrop}
                onRemove={handleRemoveWorkImage}
                onRemoveAll={handleRemoveAllWorkImages}
              />

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">ลิงก์เข้าถึงผลงาน</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <Box sx={{paddingX: '20px'}}>
                <CheckboxStyle disabled name="publicTerm" label={locale.publicTerm.label} sx={{ mb: 3 }} color='secondary'/>
              </Box>
              <TextFieldStyle name="worksLink" inputProps={autoFillStyles} placeholder='ลิงก์ GitHub / Figma / Adobe XD และ แหล่งเข้าถึงผลงาน*' color='secondary'/>

              <Box sx={{display: 'flex', marginY: '15px'}}>
                <TitleStyle variant="subtitle1">วิดีโอแนะนำผลงาน (Youtube)</TitleStyle>
                <ErrorTextStyle variant="subtitle2" color="error">*</ErrorTextStyle>
              </Box>
              <TextFieldStyle name="youtubeLink" inputProps={autoFillStyles} placeholder='ลิงก์วีดีโอแนะนำผลงานผ่าน YouTube* ' color='secondary'/>
              { validateYoutubeLink(values.youtubeLink) && <ReactPlayer url={values.youtubeLink} width="100%" onReady={() => setYoutubeError(false)} onError={() => setYoutubeError(true)}/>}
              { youtubeError && <Typography sx={{color: 'error.main'}}>*ลิงก์ youtube ไม่สามารถเปิดได้</Typography> }
              <Box sx={{paddingX: '20px'}}>
                <CheckboxStyle disabled name="nonCopyTerm" label={locale.nonCopyTerm.label} sx={{ mt: 3 }} color='secondary'/>
                <CheckboxStyle disabled name="galleryTerm" label={locale.galleryTerm.label} sx={{ mt: 3 }} color='secondary'/>
                <CheckboxStyle name="anonymous" label={locale.anonymousShow.label} sx={{ mt: 3 }} color='secondary'/>
              </Box>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{textAlign: 'end'}}>
                  { errorMessages.map((each) => <FormErrorMessage text={each}/>) }
                  <LoadingButton type="submit" variant="contained" color="secondary" loading={loading}>
                    ส่งโปรเจกต์ของฉัน<Iconify icon={'mdi:upload'} width={18} height={18} />
                  </LoadingButton>
                </Box>
              </Box>
            </FormProvider>
          </FormWrapStyle>
        </Box>
      }
    </>
  )
}

function FormErrorMessage({text}: {text: string}){
  return (
    <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '10px'}}>
      <Typography sx={{color: 'error.main'}}>{text}</Typography>
    </Box>
  )
}

const FormWrapStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '20px',
  borderRadius: '16px',
  marginTop: '30px'
}))

const TitleStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const ErrorTextStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.light,
}))

const DisabledTextFieldStyle = styled(RHFTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: theme.palette.background.neutral,
  }
}))

const TextFieldStyle = styled(RHFTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.grey[500],
    borderRadius: '8px',
    '& fieldset': {
        borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
        borderColor: theme.palette.grey[400],
    },
  }
}))

const CheckboxStyle = styled(RHFCheckbox)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  },
}))

const locale ={
  publicTerm: {
    label: 'ข้าพเจ้ายินดีเผยแพร่ Source Code / ต้นฉบับผลงานลงไปยังหน้าเว็บไซต์  DevProject Gallery สู่สาธารณะเพื่อสร้างโปรไฟล์ให้กับตัวเอง และ เพื่อประโยชน์ด้านการศึกษาแก่สมาชิกท่านอื่น ๆ ให้ได้รับชม'
  },
  nonCopyTerm: {
    label: 'ข้าพเจ้ารับรองว่าผลงานดังกล่าว ได้ดำเนินการพัฒนาด้วยความสามารถของตนเองทั้งหมด หากทางบอร์นทูเดฟ พบการคัดลอกส่วนหนึ่งส่วนใดจากโปรเจกต์ โครงการของผู้เรียนท่านอื่น หรือ ภายนอกมาดำเนินการส่ง ข้าพเจ้ายินดีให้บริษัทตัดสิทธิ์การพิจารณาขอรับใบรับรองในหลักสูตรนี้'
  },
  galleryTerm: {
    label: 'ข้าพเจ้ายินดีเผยแพร่ข้อมูลทั่วไปของโครงการซึ่งได้แก่ ชื่อผู้พัฒนา ชื่อผลงาน รายละเอียดโดยย่อโครงการ รูปภาพ และ วีดีโอ ลงในแพลตฟอร์มDevProject Gallery ที่ให้ผลงานของคุณได้ถูกแสดงสู่สาธารณะ และ ยอมรับนโยบายเกี่ยวกับการจัดส่งโครงการของบริษัท'
  },
  anonymousShow: {
    label: 'เผยแพร่โปรเจกต์โดยไม่แสดงชื่อและรูปภาพของคุณ'
  }
}
